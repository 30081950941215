import React from "react";

interface ExpertiseItemProps {
  delay: number;
  text: string;
}

const ExpertiseItem = ({ delay, text }: ExpertiseItemProps) => {
  return (
    <li
      className="mb-3 md:mb-5"
      data-aos="fade-right"
      data-aos-delay={delay}
      data-aos-once="true"
    >
      <h3 className="font-serif text-3xl sm:text-[4.5vw] md:text-[6vw] leading-tight md:leading-none">
        {text}
      </h3>
    </li>
  );
};

export default ExpertiseItem;
