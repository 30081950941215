import React from "react";

import { Talent as TalentType } from "../../types/talent";

import Talent from "../../components/Talent";

import img44 from "../../assets/images/talent__44.png";
import imgHyper from "../../assets/images/talent__hyper.png";
import imgKenny from "../../assets/images/talent__kenny.png";
import imgVincent from "../../assets/images/talent__vincent.png";

const talents: TalentType[] = [
  {
    name: "Hyper",
    img: imgHyper,
    position: "Founder/CEO",
    color: "lightyellow",
  },
  {
    name: "Kenny",
    img: imgKenny,
    position: "Co-Founder",
    color: "lightblue",
  },
  {
    name: "F404",
    img: img44,
    position: "Infrastructure",
    color: "lightpink",
  },
  {
    name: "Vincent",
    img: imgVincent,
    position: "Look & Feel",
    color: "darkseagreen",
  },
];

const Team = () => {
  return (
    <div className="flex align-center items-center min-h-[100vh]" id="team">
      <div className="py-32">
        <div className="container mx-auto px-[10vw]">
          <h2 className="text-base mb-10 tracking-wide uppercase">
            / The team
          </h2>
          <div className="md:pl-12 xl:pl-[5vw] md:w-[75vw]">
            <p className="font-serif text-3xl sm:text-[4.5vw] md:text-[6vw] leading-tight md:leading-none mb-10">
              Meet the team
            </p>
            <p className="text-base font-light xl:text-[1.5vw] md:max-w-[45vw] leading-normal mb-20">
              The special talents behind our products.
            </p>
            <div className="grid gap-4 grid-cols-2 md:grid-cols-4">
              {talents.map((talent, index) => (
                <Talent key={talent.name} data={talent} delay={index * 150} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
