import React from "react";

import ExpertiseItem from "./ExpertiseItem";

const expertiseItems = [
  "# Software Development",
  "# Researching",
  "# Training",
];

const Expertise = () => {
  return (
    <div className="flex align-center items-center min-h-[100vh]" id="works">
      <div className="py-32">
        <div className="container mx-auto px-[10vw]">
          <h2 className="text-base mb-10 tracking-wide uppercase">
            / Expertise
          </h2>
          <div className="md:pl-12 xl:pl-[5vw]">
            <p
              className="text-base font-light xl:text-[1.5vw] md:max-w-[45vw] leading-normal"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="true"
            >
              Projects are the result of our creations. We always try to pass
              those challenges with many styles and enjoy the moment when
              bringing success to our clients/end-users.
            </p>
            <ul className="mt-20">
              {expertiseItems.map((item, index) => (
                <ExpertiseItem key={item} delay={index * 150} text={item} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expertise;
