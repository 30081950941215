import { Link } from "gatsby";
import React, { useMemo } from "react";

import { Marker } from "./Icons";

const Footer = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className="flex align-center items-center min-h-[100vh]">
      <div className="container mx-auto px-[10vw]">
        <button
          className="font-serif text-5xl sm:text-[4.5vw] md:text-[10vw] leading-tight md:leading-none mb-20 underline md:no-underline text-brand relative after:hover:content-[''] after:hover:absolute after:hover:inset-x-4 after:hover:bottom-0 after:hover:bg-brand after:hover:h-2"
          data-aos="zoom-in"
          data-aos-once="true"
          type="button"
        >
          Let's Talk.
        </button>
        <p className="mb-10 flex items-end">
          <Marker /> Saigon, Vietnam
        </p>
        <div className="flex justify-between items-center ">
          <span className="font-light">&copy; {currentYear}. SEstudio</span>
          <Link
            to="/terms"
            className="text-[#1967d2] hover:text-[#174ea6] focus:text-[#174ea6]"
          >
            Terms
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
