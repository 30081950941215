import React, { useMemo } from "react";

import { Talent as TalentType } from "../types/talent";

interface TalentProps {
  data: TalentType;
  delay: number;
}

const Talent = ({ data, delay }: TalentProps) => {
  const classNames = useMemo(
    () =>
      `w-32 h-32 mx-auto mx-auto mb-2 rounded-full overflow-hidden object-cover border-2 border-blue-50 bg-${data.color}`,
    [data.color]
  );

  return (
    <div
      className="text-center mb-6"
      data-aos="fade-up"
      data-aos-delay={delay}
      data-aos-once="true"
    >
      <div className={classNames}>
        <img
          className="w-32 h-32 translate-y-1"
          src={data.img}
          alt={data.name}
        />
      </div>
      <p className="text-lg">{data.name}</p>
      <p className="text-sm text-gray-500">{data.position}</p>
    </div>
  );
};

export default Talent;
