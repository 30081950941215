import AOS from "aos";
import { HeadFC } from "gatsby";
import React, { useEffect } from "react";

import Layout from "../components/Layout";

import About from "../fragments/Home/About";
import Expertise from "../fragments/Home/Expertise";
import Introduction from "../fragments/Home/Introduction";
import Team from "../fragments/Home/Team";

import "aos/dist/aos.css";

const HomePage = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Layout>
      <Introduction />
      <Expertise />
      <About />
      <Team />
    </Layout>
  );
};

export const Head: HeadFC = () => <title>Homepage | SEstudio</title>;

export default HomePage;
