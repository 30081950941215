import { Link } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";

import { Close, Menu } from "./Icons";

import NavbarMenu from "../fragments/NavbarMenu";

import imgLogo from "../assets/images/se-studio.png";

const NavBar = () => {
  const [isOpenNavbarMenu, setIsOpenNavbarMenu] = useState<boolean>(false);
  const [isShowLogo, setIsShowLogo] = useState<boolean>(true);

  const onToggleNavbarMenu = useCallback(() => {
    setIsOpenNavbarMenu(!isOpenNavbarMenu);
  }, [isOpenNavbarMenu]);

  const onCloseNavbarMenu = useCallback(() => {
    setIsOpenNavbarMenu(false);
  }, []);

  const onScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const viewportHeight = window.innerHeight;
    const contentHeight = document.body.clientHeight;

    if (winScroll < 100 || winScroll >= contentHeight - viewportHeight - 100) {
      setIsShowLogo(true);
    } else {
      setIsShowLogo(false);
    }
  };

  const disableScroll = () => {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflowX = "hidden";
    body.style.overflowY = "hidden";
  };

  const enableScroll = () => {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflowX = "auto";
    body.style.overflowY = "auto";
  };

  useEffect(() => {
    if (isOpenNavbarMenu) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [isOpenNavbarMenu]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <nav className="fixed top-0 right-0 md:right-[10vw] z-50">
        <div className="container mx-auto px-10">
          <div className="flex justify-end ">
            <div
              className={`flex items-center gap-2 ${
                isOpenNavbarMenu ? "mr-4" : "mr-0"
              }`}
            >
              <Link
                to="/"
                className={`transition-all ${
                  isShowLogo ? "opacity-100" : "opacity-0"
                }`}
              >
                <img src={imgLogo} alt="SEstudio" className="w-36 h-36" />
              </Link>
              <button className="md:scale-150" onClick={onToggleNavbarMenu}>
                {isOpenNavbarMenu ? <Close /> : <Menu />}
              </button>
            </div>
          </div>
        </div>
      </nav>
      {isOpenNavbarMenu && <NavbarMenu onCloseMenu={onCloseNavbarMenu} />}
    </>
  );
};

export default NavBar;
