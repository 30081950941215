import React, { ReactNode, useEffect } from "react";

import Background from "./Background";
import Footer from "./Footer";
import NavBar from "./NavBar";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBar />
      {children}
      <Footer />
      <Background />
    </div>
  );
};

export default Layout;
