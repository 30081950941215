import React from "react";

import { Logo } from "../../components/Icons";

const About = () => {
  return (
    <div className="flex align-center items-center min-h-[100vh]" id="about">
      <div className="py-32">
        <div className="container mx-auto px-[10vw]">
          <h2 className="text-base mb-10 tracking-wide uppercase">
            / What we believe in
          </h2>
          <div
            className="md:pl-12 xl:pl-[5vw]"
            data-aos="zoom-in"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <div className="mb-10">
              <Logo className="text-brand" />
            </div>
            <p
              className="text-base font-light xl:text-[1.5vw] md:max-w-[45vw] leading-normal"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="150"
              data-aos-once="true"
            >
              With many years in the software engineering industry, we want to
              build a place where our engineers can try and apply their creative
              solutions to make life easier, help brands grow, and transform
              businesses to grow within the digital world.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
