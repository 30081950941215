import React from "react";

const Introduction = () => {
  return (
    <div
      className="flex align-center items-center min-h-[100vh]"
      id="introduction"
    >
      <div className="py-32">
        <div className="container mx-auto px-[10vw]">
          <h2 className="text-base mb-10 tracking-wide uppercase">
            / Introduction
          </h2>
          <div className="md:pl-12 xl:pl-[5vw]">
            <p
              className="font-serif text-3xl sm:text-[4.5vw] md:text-[6vw] leading-tight md:leading-none mb-10 text-brand"
              data-aos="zoom-in"
              data-aos-duration="500"
              data-aos-once="true"
            >
              We are the studio
              <br />
              of the Upcomers
            </p>
            <p
              className="text-base font-light xl:text-[1.5vw] md:max-w-[45vw] leading-normal"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="150"
              data-aos-once="true"
            >
              We break boundaries to create extraordinary experiences. With our
              team of professionals, we want to help you raise your brand to
              capture your customers' minds and hearts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
