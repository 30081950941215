import { Link } from "gatsby";
import React from "react";

import { NavLink } from "../../types/navLink";

interface NavbarMenuProps {
  onCloseMenu: () => void;
}

const navLinks: NavLink[] = [
  { url: "/", text: "Home" },
  { url: "/#works", text: "Expertise" },
  { url: "/#about", text: "About" },
  { url: "/#team", text: "Team" },
];

const NavbarMenu = ({ onCloseMenu }: NavbarMenuProps) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white z-40">
      <div className="flex align-center items-center min-h-[100vh]">
        <ul className="container mx-auto px-10">
          {navLinks.map((navLink) => (
            <li className="mb-6 md:mr-6" key={navLink.text}>
              <Link
                to={navLink.url}
                className="text-5xl lg:text-[6vw] transition-colors hover:text-blue-500 hover:font-serif hover:text-6xl hover:lg:text-[7.5vw] hover:italic"
                onClick={onCloseMenu}
              >
                {navLink.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavbarMenu;
